import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import { Warehouse, WarehouseCollection } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Warehouse, WarehouseCollection>
>;

@Component
export default class WarehousesMixin extends Mixins(TypedModelMixin) {
  obCollection = new WarehouseCollection();
  obModelClass = Warehouse;
  sRoutePath = "/warehouses";

  created() {
    this.onCreated();
  }
}
