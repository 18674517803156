<template>
  <gw-dialog v-model="drawer" hide-actions>
    <v-form v-if="obModel" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="isLoading"
        :dirty="isDirty"
        hide-top-actions
        @cancel="cancel"
        @save="save"
      >
        <settings v-model="obModel" @save="save" />
      </gw-form-observer>
    </v-form>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import WarehousesMixin from "@/modules/warehouses/mixins/WarehousesMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import Settings from "@/modules/warehouses/components/tabs/Settings.vue";
import GwDialog from "@/components/common/GwDialog.vue";

@Component({
  components: {
    GwDialog,
    SheetDrawer,
    Settings,
  },
})
export default class WarehousesForm extends Mixins(WarehousesMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  tab = 0;
  tabItems: string[] = ["settings"];
}
</script>
